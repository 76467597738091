/* frontend/src/components/FAQ.css */
.faq-container {
    width: 600px;
    margin: 100px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;
  }
  
  .faq-container h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .faq-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .faq-item h3 {
    margin: 0;
    font-size: 18px;
    color: #007bff;
  }
  
  .faq-item p {
    margin: 5px 0 0 0;
    font-size: 16px;
    color: #555;
  }
  