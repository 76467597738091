/* frontend/src/components/Message.css */
.message {
  position: relative;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  max-width: 60%;
  display: flex;
  flex-direction: column;
}

.message.user {
  background-color: #DCF8C6;
  align-self: flex-end;
}

.message.bot {
  background-color: #FFFFFF;
  align-self: flex-start;
}

.message p {
  margin: 0;
  font-size: 16px;
}

.feedback-icons {
  display: flex;
  gap: 10px;
  margin-top: 5px;
  cursor: pointer;
}

.feedback-icons span {
  font-size: 1.2em;
}

.feedback-popup {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  width: 250px;
}

.feedback-popup button {
  position: absolute;
  top: 5px;
  right: 10px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.feedback-popup p {
  margin: 10px 0;
}
