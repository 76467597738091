/* frontend/src/components/Auth.css */
.auth-container {
  width: 400px;
  margin: 100px auto; /* ナビゲーションバーの高さ分余白を追加 */
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
}

.auth-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.auth-container form {
  display: flex;
  flex-direction: column;
}

.auth-container input {
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #aaa;
  border-radius: 5px;
  outline: none;
}

.auth-container .password-strength {
  font-size: 14px;
  color: #555;
  margin-bottom: 10px;
}

.auth-container button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.auth-container button:hover {
  background-color: #0056b3;
}

.auth-container p {
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
}

.email-verification {
  margin-top: 20px;
  text-align: center;
}

.email-verification button {
  padding: 8px 16px;
  background-color: #ffc107;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.email-verification button:hover {
  background-color: #e0a800;
}
