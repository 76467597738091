/* frontend/src/components/Contact.css */
.contact-container {
    width: 400px;
    margin: 100px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;
  }
  
  .contact-container h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .contact-container form {
    display: flex;
    flex-direction: column;
  }
  
  .contact-container input,
  .contact-container textarea {
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #aaa;
    border-radius: 5px;
    outline: none;
  }
  
  .contact-container textarea {
    resize: vertical;
    height: 100px;
  }
  
  .contact-container button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .contact-container button:hover {
    background-color: #0056b3;
  }
  