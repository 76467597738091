/* frontend/src/components/ChatWindow.css */

.chat-window {
  display: flex;
  flex-direction: column;
  height: 80vh;
  width: 60%;
  margin: 20px auto;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.messages {
  flex: 1;
  padding: 16px;
  overflow-y: auto;
}

.input-area {
  display: flex;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #ccc;
  background-color: #fff;
}

.input-area input[type="text"] {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.input-area .icon {
  font-size: 1.5em;
  color: #555;
  margin-left: 10px;
  cursor: pointer;
}

.input-area .send-icon {
  color: #0d6efd;
}

.input-area .send-icon:hover {
  color: #0b5ed7;
}

.input-area .mic-icon {
  color: #555;
}

.input-area .mic-icon.recording {
  color: red;
}
