/* frontend/src/components/Navbar.css */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #343a40; /* 黒系背景 */
  padding: 10px 20px;
  color: white;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

.navbar-title {
  margin: 0;
  font-size: 24px;
}

.navbar-menu-icon {
  display: none;
  cursor: pointer;
}

.navbar-links {
  list-style: none;
  display: flex;
  gap: 15px;
  margin: 0;
}

.navbar-links li a,
.navbar-links li .logout-text {
  color: white;
  text-decoration: none;
  cursor: pointer;
  font-size: 16px;
}

.navbar-links li a:hover,
.navbar-links li .logout-text:hover {
  text-decoration: underline;
}

.logout-text {
  /* ログアウトテキストのスタイル */
  padding: 5px 10px;
}

.logout-text:hover {
  /* ホバー時のスタイル */
  color: #dc3545;
}

@media (max-width: 768px) {
  .navbar-menu-icon {
    display: block;
  }

  .navbar-links {
    position: fixed;
    top: 60px; /* ナビゲーションバーの高さに合わせて調整 */
    right: -100%;
    height: 100vh;
    width: 200px;
    background-color: #343a40;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding-top: 20px;
    transition: right 0.3s ease-in-out;
  }

  .navbar-links.active {
    right: 0;
  }

  .navbar-links li {
    margin: 20px 0;
  }
}
